import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';

const ContactPageStyles = styled.div`
  background: white;
  padding: 3rem 25% 8rem 25%;
  border-radius: 20px;
  margin-bottom: 8rem;
  box-shadow: 0 10px 20px var(--dark-grey);
  div {
    margin: 2rem 0;
  }
  label {
    font-size: 1.25rem;
  }
  input,
  textarea {
    box-sizing: border-box;
    width: 100%;
    border: 2px solid var(--dark-grey);
    border-radius: 10px;
    padding: 0.75rem;
    margin: 1rem 0;
  }
  input:focus,
  textarea:focus {
    outline: none;
  }

  @media screen and (max-width: 900px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export default function ContactPage() {
  return (
    <>
      <SEO title="Contact me" />
      <ContactPageStyles>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <label htmlFor="name">Full name</label>
            <input type="text" name="name" id="name" required />
          </div>
          <div>
            <label htmlFor="email">Email address</label>
            <input type="text" name="email" id="email" required />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="8" />
          </div>
          <input type="submit" value="Send message" />
        </form>
      </ContactPageStyles>
    </>
  );
}
