import React from 'react';
import styled from 'styled-components';
import ContactPage from './contact';

const SuccessNotificationStyle = styled.div`
  border-top: 8px solid #38b2ac;
  background-color: #e6fffa;
  padding: 1rem 4rem;
  margin: 2rem 6rem;
  display: flex;

  .notification-title {
    font-weight: bold;
  }

  .notification-message {
    font-size: 0.8rem;
  }

  svg {
    height: 3rem;
    width: 3rem;
    display: inline-block;
    margin: 2rem 2rem 0 0;
  }
`;

export default function ContactSuccessPage() {
  return (
    <>
      <SuccessNotificationStyle>
        <div className="notification-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div>
          <p className="notification-title">Form Submitted</p>
          <p className="notification-messsage">
            Thanks for getting in touch. We will respond to your enquiry as
            quickly as possible.
          </p>
        </div>
      </SuccessNotificationStyle>
      <ContactPage />
    </>
  );
}
